/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-04-12",
    versionChannel: "nightly",
    buildDate: "2023-04-12T00:18:41.384Z",
    commitHash: "da689b7687d6bb23f37251e902ccddabdfcc5f48",
};
